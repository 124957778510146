import React from 'react';

import { Grid, Popover, Typography } from '@mui/material';

import { Colors } from '../config/default';

export default function MuiPopover({
  anchorEl,
  setAnchorEl,
  startMessage,
  startPosition,
  endPosition,
  approvers
}) {
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popOpen = Boolean(anchorEl);
  const Role = (type) => {
    switch (type) {
      case 'Owner':
        return 'AP Person';
      case 'C_Level':
        return 'C Level';
      default:
        return type;
    }
  };

  return (
    approvers?.length > 0 && (
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none'
        }}
        PaperProps={{
          style: {
            maxWidth: '300px',
            whiteSpace: 'pre-wrap'
          }
        }}
        open={popOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: startPosition,
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: endPosition,
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Grid
          sx={{
            padding: '1em',
            fontSize: '16px',
            color: Colors.TEXTGREY,
            backgroundColor: Colors.NAV_BLACK
          }}>
          <Typography sx={{ mb: '14px' }}>
            {startMessage}{' '}
            {approvers?.length === 1
              ? 'document will be sent to:'
              : 'document will be sent in this sequence:'}
          </Typography>
          {approvers?.map((approver) => (
            <Grid container sx={{ justifyContent: 'space-between', mt: '6px' }}>
              <em>{approver?.name}</em>
              <em>{Role(approver?.role)}</em>
            </Grid>
          ))}
        </Grid>
      </Popover>
    )
  );
}
