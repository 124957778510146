import React, { useState, useEffect } from 'react';

import { Grid, Typography, CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import theme from '../theme';
import { ThemeProvider } from '@mui/material/styles';
import { Colors } from '../config/default';
import Btn from '../components/button';
import {
  searchDeliveryTicketbyVendorName,
  attachDeliveryTicket,
  getDocumentDetails,
  deleteDeliveryTicket,
  getAttachmentsbyDocId
} from '../services/services';
import { useToast } from '../toast/toastContext';
import ImageSelect from './dtImageSelect';
import SearchBar from './searchBar';
import Prompt from './prompt';
import Navigation from './navigation';
import { formatDateOnly } from '../services/common';

function AttachDeliveryTicket({
  setImages,
  setOpen,
  setRefId,
  vendor,
  po,
  invoiceNumber,
  date,
  refId,
  setIndex
}) {
  const { id } = useParams();
  const { showToast } = useToast();
  const [foundDocument, setFoundDocument] = useState([]);
  const [allDocument, setAllDocument] = useState([]);
  const [searching, setSearching] = useState(false);
  const [selectedDocumentId, setSelectedDocumentId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [searchDocumnet, setSearchDocuments] = useState('');
  const [searchedResult, setSearchedResult] = useState([]);
  const [isDocumentSearched, setIsDocumentSearched] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState([]);
  const [attachDocs, setAttachDocs] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [navigationValue, setNavigationValue] = useState(0);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const companyId = userDetails?.company?._id;

  const serachDT = async (showAllDT) => {
    setSearching(true);
    const response = await searchDeliveryTicketbyVendorName(
      vendor,
      formatDateOnly(date),
      po ? po : 'N/A',
      invoiceNumber,
      showAllDT
    );
    if (response?.status === 200) {
      if (!showAllDT) {
        setAllDocument(response?.data?.payload?.data);
        setShowAll(true);
      } else {
        setFoundDocument(response?.data?.payload?.data);
      }
    }
    setSearching(false);
  };
  const getAttachedDT = async () => {
    setIsLoading(true);
    const response = await getAttachmentsbyDocId(id);
    if (response?.status === 200) {
      setAttachDocs(response?.data?.payload?.data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    serachDT(true);
    getAttachedDT();
  }, []);

  const handleAttachFile = async () => {
    setLoading(true);
    const params = {
      hostDocumentId: id,
      referenceDocument: selectedDocumentId,
      companyId: companyId
    };
    const result = await attachDeliveryTicket(params);
    if (result?.status === 200) {
      const res = await getDocumentDetails(id, 'app');
      if (res?.status === 200) {
        setImages(res?.data?.payload?.data?.document?.convertedS3Keys);
        setRefId(res?.data?.payload?.data?.document?.documentReference);
      }
      showToast(result?.data?.metadata?.message, 'success');
      setOpen(false);
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }

    setLoading(false);
  };
  const handleClearSearch = () => {
    setIsDocumentSearched(false);
    setSearchDocuments('');
    setSearchedResult([]);
  };

  const handleSearchDocument = () => {
    if (!searchDocumnet) return;
    setSearching(true);
    let filteredResults;
    if (!isEmpty(allDocument)) {
      filteredResults = allDocument?.filter((doc) =>
        doc?.standardizeContent?.document_number?.includes(searchDocumnet)
      );
    } else {
      filteredResults = foundDocument?.filter((doc) =>
        doc?.standardizeContent?.document_number?.includes(searchDocumnet)
      );
    }
    if (!isEmpty(filteredResults)) {
      setSearchedResult(filteredResults);
      setIsDocumentSearched(true);
    } else {
      setSearchedResult([]);
      setIsDocumentSearched(true);
    }

    setSearching(false);
  };
  const handleOpen = () => {
    setOpenPrompt(true);
  };
  const handleDeleteAttachment = async () => {
    setDeleting(true);
    const params = {
      hostDocumentId: id,
      referenceDocument: deleteDocumentId,
      companyId: companyId
    };
    const result = await deleteDeliveryTicket(params);
    if (result?.status === 200) {
      const res = await getDocumentDetails(id, 'app');
      if (res?.status === 200) {
        setImages(res?.data?.payload?.data?.document?.convertedS3Keys);
        setRefId(res?.data?.payload?.data?.document?.documentReference);
      }
      setIndex(0);
      showToast(result?.data?.metadata?.message, 'success');
      setOpenPrompt(false);
      setOpen(false);
    } else {
      showToast(
        result?.response?.data?.metadata?.message || result?.data?.metadata?.message,
        'error'
      );
    }
    setDeleting(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container item xs={12} sx={{ padding: '.5rem' }}>
        <Typography variant="h6" sx={{ color: Colors.WHITE }}>
          Delivery Tickets
        </Typography>

        <Grid
          item
          xs={12}
          sx={{
            height: '57vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            overflowY: 'auto',
            display: 'flex',
            margin: '1rem 0',
            '&::-webkit-scrollbar': {
              width: '.2em',
              height: '0em',
              borderRadius: '20px'
            },
            '&::-webkit-scrollbar-track': {
              margin: '1rem 0rem',
              borderRadius: '20px',
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
              outline: '1px solid slategrey'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#fff',
              outline: '1px solid slategrey'
            }
          }}>
          <Grid
            container
            sx={{
              justifyContent: navigationValue === 0 ? 'space-between' : 'flex-end',
              height: 'max-content'
            }}>
            {navigationValue === 0 && (
              <SearchBar
                width="350px"
                setSearchProducts={setSearchDocuments}
                text="Search Delivery Ticket..."
                searchProducts={searchDocumnet}
                tooltipText="Search by Document No"
                handleKeyPress={handleSearchDocument}
                handleClear={handleClearSearch}
                recordExist={isDocumentSearched}
                filteredData={searchedResult}
              />
            )}
            <Navigation
              value={navigationValue}
              setValue={setNavigationValue}
              label1="Attach"
              label2="Remove"
            />
          </Grid>
          {navigationValue === 1 ? (
            <>
              {!isEmpty(refId) && !isEmpty(attachDocs) ? (
                <>
                  <Typography sx={{ color: Colors.TOURQUISE, marginTop: '1rem' }}>
                    Attached Delivery Tickets
                  </Typography>
                  <Grid container spacing={1} sx={{ padding: '1rem .5rem .5rem 0rem' }}>
                    {attachDocs?.map((data) => {
                      return (
                        <ImageSelect
                          data={data}
                          btnText=""
                          selectedDocumentId={deleteDocumentId}
                          setSelectedDocumentId={setDeleteDocumentId}
                          variant="caption"
                          fontLength={9}
                          url={data?.convertedS3Keys[0]}
                        />
                      );
                    })}
                  </Grid>
                </>
              ) : (
                <Grid
                  container
                  sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{ color: Colors.TEXTGREY }}>No Delivery Tickets Attached</p>
                </Grid>
              )}
            </>
          ) : (
            <>
              {searching || isloading ? (
                <Grid
                  container
                  sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                  <CircularProgress size={60} color="primary" />
                </Grid>
              ) : isEmpty(foundDocument) && isEmpty(refId) && isEmpty(allDocument) && showAll ? (
                <Grid
                  container
                  sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{ color: Colors.TEXTGREY }}> No Delivery Tickets Found</p>
                </Grid>
              ) : isEmpty(foundDocument) && isEmpty(allDocument) ? (
                <Grid
                  container
                  sx={{ height: '50%', alignItems: 'flex-end', justifyContent: 'center' }}>
                  <p style={{ color: Colors.TEXTGREY }}> No Suggetsed Delivery Tickets</p>
                </Grid>
              ) : isDocumentSearched ? (
                <>
                  {!isEmpty(searchedResult) ? (
                    <Grid container spacing={1} sx={{ padding: '1rem .5rem .5rem 0rem' }}>
                      {searchedResult?.map((data) => {
                        return (
                          <ImageSelect
                            data={data}
                            btnText=""
                            selectedDocumentId={selectedDocumentId}
                            setSelectedDocumentId={setSelectedDocumentId}
                            variant="caption"
                            fontLength={9}
                            url={data?.convertedS3Keys[0]}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Grid
                      container
                      sx={{ height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                      <p style={{ color: Colors.TEXTGREY }}>No Delivery Tickets Found</p>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {!isEmpty(foundDocument) && (
                    <Typography sx={{ color: Colors.TOURQUISE, mt: '1rem' }}>
                      Suggested Delivery Tickets
                    </Typography>
                  )}

                  <Grid container spacing={1} sx={{ padding: '1rem .5rem .5rem 0rem' }}>
                    {foundDocument?.map((data) => {
                      return (
                        <ImageSelect
                          data={data}
                          btnText=""
                          selectedDocumentId={selectedDocumentId}
                          setSelectedDocumentId={setSelectedDocumentId}
                          variant="caption"
                          fontLength={9}
                          url={data?.convertedS3Keys[0]}
                        />
                      );
                    })}
                  </Grid>
                </>
              )}
              <>
                {!showAll && !isDocumentSearched && !searching && !isloading && (
                  <Grid
                    container
                    sx={{
                      justifyContent: 'center',
                      marginTop: '.2rem'
                    }}>
                    <Typography
                      variant="caption"
                      sx={{ color: Colors.TOURQUISE, cursor: 'pointer' }}
                      onClick={() => serachDT(false)}>
                      Show All Delivery Tickets
                    </Typography>
                  </Grid>
                )}
                {showAll && !isEmpty(allDocument) && !isDocumentSearched && (
                  <>
                    <Typography sx={{ color: Colors.TOURQUISE, marginTop: '.5rem' }}>
                      All Delivery Tickets
                    </Typography>
                    <Grid container spacing={1} sx={{ padding: '1rem .5rem .5rem 0rem' }}>
                      {allDocument?.map((data) => {
                        return (
                          <ImageSelect
                            data={data}
                            btnText=""
                            selectedDocumentId={selectedDocumentId}
                            setSelectedDocumentId={setSelectedDocumentId}
                            variant="caption"
                            fontLength={9}
                            url={data?.convertedS3Keys[0]}
                          />
                        );
                      })}
                    </Grid>
                  </>
                )}
              </>
            </>
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {navigationValue === 1 && (
            <>
              <Prompt
                title="Delete Delivery Tickets"
                description="Are you sure you want to proceed?"
                leftButton="Cancel"
                rightButton="Delete"
                setOpen={setOpenPrompt}
                open={openPrompt}
                opacity={false}
                handleClick={handleDeleteAttachment}
                loading={deleting}
              />
              <Btn
                width={{ xs: '9rem', xl: '100%' }}
                disabled={isEmpty(deleteDocumentId)}
                text="Remove"
                backgroundColor={Colors.RED}
                height="2.2rem"
                radius="10px"
                color={Colors.WHITE}
                onClick={handleOpen}
                disabledColor={Colors.NAV_BLACK}
              />
            </>
          )}
          {navigationValue === 0 && (
            <Btn
              width={{ xs: '9rem', xl: '100%' }}
              loading={loading}
              disabled={isEmpty(selectedDocumentId)}
              text="Attach"
              backgroundColor={Colors.DEEPBLUE}
              height="2.2rem"
              radius="10px"
              color={Colors.WHITE}
              onClick={handleAttachFile}
              disabledColor={Colors.NAV_BLACK}
            />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default AttachDeliveryTicket;
